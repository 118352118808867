import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import NavBar from '../components/navBar/NavBar'
import Footer from '../components/footer/Footer'
import { useDispatch, useSelector } from "react-redux";
import { CALCULATE_SUBTOTAL, CALCULATE_TOTAL_QUANTITY, selectCartItems, selectCartTotalAmount } from "../../redux/slice/cartSlice";
import { selectEmail } from "../../redux/slice/authSlice";
import { selectBillingAddress, selectShippingAddress } from "../../redux/slice/checkoutSlice";
import { toast } from "react-toastify";
import CheckoutForm from "../components/checkoutForm/CheckoutForm";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function Checkout() {
  const [message, setMessage] = useState("Initailizing checkout...");
  const [clientSecret, setClientSecret] = useState("");

  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectCartTotalAmount);
  const customerEmail = useSelector(selectEmail);
  const shippingAddress = useSelector(selectShippingAddress);
  const billingAddress = useSelector(selectBillingAddress);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL())
    dispatch(CALCULATE_TOTAL_QUANTITY())
  },[dispatch, cartItems]);
    
  const description = `Rimberio payment : email: ${customerEmail}, Amount: ${totalAmount}`

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:4242/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        items:  cartItems,
        userEmail : customerEmail,
        shipping : shippingAddress,
        billing : billingAddress,
        description
      }),
    })
      .then((res) => {
        if(res.ok) {
          return res.json();
        }
        return res.json().then((json) => Promise.reject(json))
      })
      .then((data) => {
        setClientSecret(data.clientSecret)
      })
      .catch((error) => {
        setMessage("Failed to Initialize Checkout")
        toast.error("Something went wrong!!!")
      })
  }, [billingAddress, cartItems, customerEmail, description, shippingAddress]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <NavBar/>
      <div className="containerss">
        {!clientSecret && <h3>{message}</h3>}
      </div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
      <Footer />
    </>
  )
}
