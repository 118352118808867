export const sliders = [
  {
    img1: "./img/imgIns/ins10.jpg",
    imgP: "02: Women's mini outfit",
    exclus: "Exclusive",
    coll: "Collection",
    img2: "./img/imgIns/ins11.jpg",
    imgP1: "01: Women's cottons cloth",
  },
  {
    img1: "./img/pexels-photo-6310924.jpeg",
    imgP: "02: Men's Winter denim coat",
    exclus: "Lama",
    coll: "Collection",
    img2: "./img/man-2911327_1280.jpg",
    imgP1: "01: Men's coats with stones",
  },
  {
    img2: "./img/children-model-3336530_1280.jpg",
    imgP1: "01: Children's Winter denim coat",
    exclus: "Limited",
    coll: "Edition",
    img1: "./img/pexels-photo-5560019.jpeg",
    imgP: "02: Children's Sweaters",
  },
  {
    img2: "./img/stock-photo-247983873.jpg",
    imgP1: "02: Apples Wrist Watch",
    exclus: "New",
    coll: "Sales",
    img1: "./img/stock-photo-1050248206.jpg",
    imgP: "01: Men's nice Strong Shoes",
  },
  {
    img1: "./img/stock-photo-1069466938.jpg",
    imgP: "02: Headphones",
    exclus: "Exclusive",
    coll: "Gadget",
    img2: "./img/stock-photo-73945253.jpg",
    imgP1: "01: Lens Camera",
  },
];
